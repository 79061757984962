@font-face {
  font-family: ModeSeven;
  src: url(../assets/mode7gx3.ttf);
}

.theme-classic {
  --color-background: #212121;
  --color-toolbar: #333;
  --color-buttons: #595959;
  --color-editor-bg: #171717;
  --font-color: #bbb;
  --color-highlight: #5e8040;
  --button-highlight: #6f2020;
  --button-blue: #00536f;
}

html,
body {
  font-family: "Roboto Mono", monospace;
  font-size: small;
}

a:link,
a:visited,
a:hover,
a:active {
  color: white;
  text-decoration: none;
}

*:focus {
  outline: 0 !important;
}

body {
  color: var(--font-color);
  background: var(--color-background);
  padding: 0;
  margin: 0;
}

.top-level-wrapper {
  height: 90vh;
  display: grid;
  grid-template-columns: minmax(320px, 1fr) minmax(320px, 1fr);
  grid-template-rows: 80px 24px minmax(320px, 90vh) 24px 32px;
}

.header {
  align-items: center;

  display: flex;
  direction: row;
}

.logo {
  height: 64px;
  padding: 8px;
  margin: 8px;
  align-items: center;
  vertical-align: middle;

  cursor: pointer;
}

h1 {
  display: inline;
  color: #eee;
  font-size: 24px;
  padding: 8px;
  vertical-align: top;
  text-align: center;
}

.toolbar {
  grid-column: span 1;
  background-color: var(--color-toolbar);
  border: 1px solid var(--color-background);
  display: flex;
}

.kudos {
  color: #eee;
  padding: 8px 4px 4px 16px;
  grid-column: span 2;
  background-color: #111; //var(--color-editor-bg);
  border: 0;
}

.code-editor {
  background-color: var(--color-background);
  grid-template-rows: 80px 24px minmax(320px, 90vh) 24px 32px;
}

.too_long {
  color: Tomato;
}

.emulator {
  background: var(--color-editor-bg);
  justify-content: center;
  align-content: center;
}

#emu_footer,
#editor_footer {
  display: flex;
  align-items: center;
  grid-column: span 1;
  background-color: var(--color-toolbar);
  border: 1px solid var(--color-background);
  //font-family: "SF Mono", Monaco, Menlo, Consolas, "Ubuntu Mono", "Liberation Mono", "DejaVu Sans Mono", "Courier New", monospace;
  color: var(--color-highlight);
}

button,
button:active {
  height: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: 4px;
  text-decoration: none;
  color: var(--font-color);
  border: 0px solid var(--color-toolbar);
  background-color: var(--color-buttons);
  //    background-color: #8064A2 !important;
}

button:active {
  background-color: var(--color-highlight);
}

canvas.screen {
  border: none;
}

#about {
  padding: 32px;
  display: none;
  height: 100%;
}

#edit_status {
  padding-left: 16px;
  justify-content: left;
}

#emu_status {
  padding-left: 16px;
  justify-content: right;
}

.template {
  display: none;
}

#examples {
  margin: 8px;
  height: 90%;
  display: none;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-wrap: wrap;

  .code {
    font-family: ModeSeven;
  }
}

@-o-keyframes fadeIt {
  0% {
    background-color: #ffffff;
  }
  50% {
    background-color: #ad301b;
  }
  100% {
    background-color: #ffffff;
  }
}

@keyframes fadeIt {
  0% {
    background-color: #ffffff;
  }
  50% {
    background-color: #ad301b;
  }
  100% {
    background-color: #ffffff;
  }
}

.backgroundAnimated {
  background-image: none !important;
  -o-animation: fadeIt 2s ease-in-out;
  animation: fadeIt 2s ease-in-out;
  animation-iteration-count: infinite;
}

.example {
  background-color: var(--color-background);
  padding: 4px;
  margin-bottom: 8px;
  height: 100px;
  display: flex;
}

.template {
  display: none;
}

.name {
  margin-left: 16px;
}

.description {
  margin-left: 16px;
}

.example:nth-of-type(2) {
  background: var(--button-highlight);
}

h2 {
  padding: 0px;
  margin: 0px;
  margin-bottom: 4px;
}

pre {
  margin-left: 16px;
  color: grey;
}

span.heart {
  color: red;
  font-size: large;
  margin-right: 4px;
}

input {
  width: 100%;
  margin-bottom: 16px;
}

// Modal pop-up
/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: var(--font-color);
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid var(--color-background);
  width: 80%; /* Could be more or less, depending on screen size */
  color: var(--color-background);
}

/* The Close Button */
.close {
  color: var(--color-background);
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

#mobile {
  display: none;
}

@media (orientation: portrait) {
  .top-level-wrapper {
    height: 90vh;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 80px 32px 80vw 32px 32px minmax(320px, 90vh) 32px 50vh;
  }

  .header {
    display: inline;
  }

  #emu_toolbar {
    grid-column: 1;
    grid-row: 7;
  }

  #editor_toolbar {
    display: none;
  }

  .kudos {
    color: #eee;
    padding: 8px 4px 4px 16px;
    grid-column: 1;
    grid-row: 4;
    background-color: #111; //var(--color-editor-bg);
    border: 0;
  }

  .code-editor {
    grid-column: 1;
    grid-row: 6;
  }

  .too_long {
    color: Tomato;
  }

  .emulator {
    background: var(--color-editor-bg);
    justify-content: center;
    align-content: center;
    grid-column: 1;
    grid-row: 3;
  }

  #emu_footer {
    grid-column: 1;
    grid-row: 2;
  }

  #editor_footer {
    grid-column: 1;
    grid-row: 5;
  }

  #mobile {
    grid-column: 1;
    grid-row: 8;
    padding: 24px 4px 4px 16px;
    display: block;
    justify-content: center;
    align-content: center;
  }
}
